<template>
    <div class="company-info-form">
        <div>
            <CNInput v-model="formData.company_name" :invalid="!!validationErrors.company_name"
                     @blur="handleChangeField('company_name', $event)" maxlength="100" label="Company Name"
                     :required="false"/>
            <CFormText v-if="validationErrors.company_name" :style="`color: ${validationErrors.company_name && 'red'}`">
                {{ validationErrors.company_name }}
            </CFormText>
        </div>

        <div>
            <CNInput :numbers-only="true" v-model="formData.company_tid" :invalid="!!validationErrors.company_tid"
                     @blur="handleChangeField('company_tid', $event)" max-length="9" label="Company Tax IN"
                     :required="false"/>
            <CFormText v-if="validationErrors.company_tid" :style="`color: ${validationErrors.company_tid && 'red'}`">
                {{ validationErrors.company_tid }}
            </CFormText>
        </div>

        <div>
            <CNInput v-model="formData.company_address_street" :invalid="!!validationErrors.company_address_street"
                     @blur="handleChangeField('company_address_street', $event)" maxlength="100"
                     label="Street Address" :required="false"/>
            <CFormText v-if="validationErrors.company_address_street"
                       :style="`color: ${validationErrors.company_address_street && 'red'}`">
                {{ validationErrors.company_address_street }}
            </CFormText>
        </div>

        <div>
            <CNInput v-model="formData.company_address_city" :invalid="!!validationErrors.company_address_city"
                     @blur="handleChangeField('company_address_city', $event)" maxlength="100" label="City"
                     :required="false"/>
            <CFormText v-if="validationErrors.company_address_city"
                       :style="`color: ${validationErrors.company_address_city && 'red'}`">
                {{ validationErrors.company_address_city }}
            </CFormText>
        </div>

        <div>
            <CNInput v-model="formData.company_address_zip" :numbers-only="true"
                     :invalid="!!validationErrors.company_address_zip"
                     @blur="handleChangeField('company_address_zip', $event)" max-length="5" label="Zip Code"
                     :required="false"/>
            <CFormText v-if="validationErrors.company_address_zip"
                       :style="`color: ${validationErrors.company_address_zip && 'red'}`">
                {{ validationErrors.company_address_zip }}
            </CFormText>
        </div>

        <div>
            <CNSelect :caret="true" :searchable="true" :invalid="!!validationErrors.company_address_state"
                      :options="states" v-model="formData.company_address_state" label="Select State"
                      @change="handleChangeField('company_address_state', $event)"/>
            <CFormText v-if="validationErrors.company_address_state"
                       :style="`color: ${validationErrors.company_address_state && 'red'}`">
                {{ validationErrors.company_address_state }}
            </CFormText>
        </div>

        <div class="company-info-form__buttons mt-4">
            <CButton color="primary" variant="outline" :disabled="loading" @click="$emit('toggleStep', 'user-info')">
                Previous
            </CButton>
            <CButton color="primary" @click="submit">
                <CSpinner v-if="loading" size="sm"/>
                <span v-else>Next</span>
            </CButton>
        </div>
    </div>
</template>

<script>
import validator from "@/utils/validator";
import rules from "@/utils/validator/rules";
import CNInput from "@/components/ui/CNInput/CNInput.vue";
import CNSelect from "@/components/ui/CNSelect/CNSelect.vue";

export default {
    name: "CompanyInfoForm",
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        prefillData: {
            type: Object,
            required: true
        }
    },
    components: {CNSelect, CNInput},
    data() {
        return {
            formData: {
                company_name: '',
                company_tid: '',
                company_address_city: '',
                company_address_state: '',
                company_address_zip: '',
                company_address_street: '',
                plan_id: 1
            },

            states: [],
            submitLoading: false,

            validator: {},
            validationErrors: {}
        }
    },
    created() {
        this.setRules();
        this.fetchStates();
        this.prefill();
    },
    methods: {
        submit() {
            const validationResult = this.validator.validateAll(this.formData);

            if (!validationResult.hasErrors) {
                this.$emit('formFilled', this.formData)
            } else {
                this.validationErrors = validationResult.validationErrors
            }
        },
        handleChangeField(field, value) {
            this.validationErrors[field] = this.validator.validate(
                field,
                value,
            )
        },
        setRules() {
            this.validator = validator({
                company_name: [rules.required],
                company_tid: [rules.required, rules.strMin(9)],
                company_address_street: [rules.required, rules.strMax(255)],
                company_address_city: [rules.required, rules.strMax(255)],
                company_address_state: [rules.required],
                company_address_zip: [
                    rules.required,
                    rules.strMin(5),
                    rules.strMax(10),
                ],
            })
        },
        fetchStates() {
            this.states = [
                'Alabama',
                'Alaska',
                'Arizona',
                'Arkansas',
                'California',
                'Colorado',
                'Connecticut',
                'Delaware',
                'Florida',
                'Georgia',
                'Hawaii',
                'Idaho',
                'Illinois',
                'Indiana',
                'Iowa',
                'Kansas',
                'Kentucky',
                'Louisiana',
                'Maine',
                'Maryland',
                'Massachusetts',
                'Michigan',
                'Minnesota',
                'Mississippi',
                'Missouri',
                'Montana',
                'Nebraska',
                'Nevada',
                'New Hampshire',
                'New Jersey',
                'New Mexico',
                'New York',
                'North Carolina',
                'North Dakota',
                'Ohio',
                'Oklahoma',
                'Oregon',
                'Pennsylvania',
                'Rhode Island',
                'South Carolina',
                'South Dakota',
                'Tennessee',
                'Texas',
                'Utah',
                'Vermont',
                'Virginia',
                'Washington',
                'West Virginia',
                'Wisconsin',
                'Wyoming',
            ]
        },
        prefill() {
            this.formData = {...this.formData, ...this.prefillData};
        }
    }
}
</script>

<style scoped lang="scss">
.company-info-form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__buttons {
        display: flex;
        gap: 24px;
    }

    &__plan {
        background: #ABE7FA80;
        padding: 16px 24px;
        border-radius: 8px;

        & h4 {
            font-size: 20px;
            color: #000000;
            margin-bottom: 17px;
        }

        &-options {
            display: flex;
            gap: 32px;
            padding-top: 24px;
            border-top: 1px solid #2BB0E0;
            font-size: 14px;
            color: #1C262F;
        }
    }
}
</style>
